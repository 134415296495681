import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from 'emotion';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background #fff;
    padding-bottom: 4vw;
  }
`;


const About: React.FunctionComponent = () => (
  <IndexLayout>
    <Helmet>
      <title>Now</title>
    </Helmet>
    <Wrapper className={`${PageTemplate}`}>
      <header className={`${SiteHeader} ${outer}`}>
        <div className={`${inner}`}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
        <article className={`${PostFull} post page ${NoImage}`}>
          <PostFullHeader>
            <PostFullTitle>Now</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                What I'm doing now.
              </p>
              <p>
                (This is a now page, and if you have your own site, <a href='https://nownownow.com/about'>you should make one</a>, too.)
              </p>
              <p>
                <h3>Fun:</h3>
                We got a switch recently and I am really enjoying spending time with the whole family playing games.   We had an awesome full family Mario Party battle the other day.   We are also slowly introducing our daughter to the marvel movie series.   
              </p>
              <p>
                <h3>Work:</h3>
                About a year ago in a mid career crisis I changed from being a day to day leader and went back into building as many things as I could with my own bare hands.   My current focus is delivering as much value as I can by focusing on using my leadership/business experience and continuous learning on software skills.   I am learning a lot and plan to share as much of that as I can on this site.  I just recently finished an Azure function project that was very enlightening.
              </p>
              <p>
                <h3>Health:</h3>
                I recently read the longevity diet and am slowly making changes from that book into my life (yay for the return of carbs).  I am also experimenting with Win Hof's breathing techniques and winter time is sauna time for me.  I am trying to create healthier habits of working out daily and might start spinning.
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
